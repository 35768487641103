<template>
  <div
    class="d-flex justify-content-center mb-4 sizeSelects"
    v-bind:class="{ allSizes: enable_height && enable_width }"
  >
    <!-- <vs-select
      v-if="enable_height"
      v-on:input="emitHeight()"
      class="selectSize"
      :label="height"
      v-model="selectedHeight"
    >
      <vs-select-item
        :is-selected.sync="item.isSelected"
        :key="index"
        :value="item.value"
        :text="item.label.toUpperCase()"
        v-for="(item, index) in optionsSize"
      />
    </vs-select>-->
    <div class="w-60" v-if="enable_height">
      <label class="typo__label">{{ height }}</label>
      <multiselect
        label="label"
        :allow-empty="false"
        v-model="selectedHeight"
        :options="optionsSize"
        :searchable="false"
        :close-on-select="true"
        :show-labels="false"
        placeholder="Pick a value"
        @select="emitHeight"
      ></multiselect>
    </div>
    <vs-select
      v-on:input="emitWidth()"
      v-if="enable_width"
      class="selectSize"
      :label="width"
      v-model="selectedWidth"
    >
      <vs-select-item
        :is-selected.sync="item.isSelected"
        :key="index"
        :value="item.value"
        :text="item.label.toUpperCase()"
        v-for="(item, index) in optionsSize"
      />
    </vs-select>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  props: {
    enable_height: {
      type: Boolean,
      required: true
    },
    enable_width: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      heightSave: '',
      selectedHeight: '',
      selectedWidth: '',
      optionsSize: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botMessageInEdit',
      'botMakerMessages',
      'botId',
      'activeChannelView'
    ]),
    height() {
      return this.lang.botMaker.botEditor.messageType.RcsCard.size_height[
        this.languageSelected
      ]
    },
    width() {
      return this.lang.botMaker.botEditor.messageType.RcsCard.size_width[
        this.languageSelected
      ]
    }
  },
  methods: {
    emitHeight(value) {
      this.$emit('height', value.value)
    },
    emitWidth() {
      this.$emit('width', this.selectedWidth)
    },
    loadHeight(height) {
      this.heightSave = height + ''
      this.selectedHeight = this.getHeight(height)
    },
    clean() {
      if (this.heightSave) {
        this.selectedHeight = this.getHeight(this.heightSave)
      } else {
        this.selectedHeight = this.getMedium()
      }
    },
    getHeight(value) {
      return this.optionsSize.find(el => {
        return el.value == value
      })
    },
    erase() {
      this.selectedHeight = this.getMedium()
      this.$emit('height', this.selectedHeight.value)
    },
    getMedium() {
      return this.optionsSize.find(el => {
        return el.value == 'MEDIUM'
      })
    }
  },
  created() {
    this.optionsSize = [
      {
        label: this.lang.botMaker.botEditor.messageType.RcsCard.sizes_large[
          this.languageSelected
        ],
        value: 'LONG',
        isSelected: false
      },
      {
        label: this.lang.botMaker.botEditor.messageType.RcsCard.sizes_medium[
          this.languageSelected
        ],
        value: 'MEDIUM',
        isSelected: false
      },
      {
        label: this.lang.botMaker.botEditor.messageType.RcsCard.sizes_short[
          this.languageSelected
        ],
        value: 'SHORT',
        isSelected: false
      }
    ]
    this.erase()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.sizeSelects {
  &.allSizes {
    justify-content: space-between;
    max-width: 350px;
    margin: auto;
    .con-select.selectSize {
      width: 45%;
    }
  }
}

.w-100 {
  width: 100%;
}

.w-60 {
  width: 60%;
}

// vue-multiselect
.multiselect {
  margin-top: 4px;
  max-height: 38px;
  min-width: 50%;
}

.multiselect__option {
  padding: 10px;
  min-height: 30px;

  &--disabled {
    color: black !important;
    font-weight: bold;
  }
}

.multiselect__option--highlight {
  background: rgba(var(--vs-primary), 1) !important;
}

.multiselect__single,
.multiselect__placeholder,
.multiselect__input,
.multiselect__option,
.multiselect__tags {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.multiselect__select,
.multiselect__tags {
  display: block !important;
}

.multiselect__content-wrapper {
  z-index: 999 !important;

  .slot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    .actions {
      padding: 0 0 10px 0;
      display: flex;
      flex-direction: row !important;
      flex-grow: 1;
      justify-content: space-evenly !important;

      .vs-button {
        max-width: 100px;
      }
    }
  }
}

.multiselect__input {
  padding: 0;
}

.multiselect--disabled .multiselect__select {
  background: white;
  margin-right: 0 !important;
}
</style>
